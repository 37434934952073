import { Link } from "@remix-run/react";
import type { DOMNode, HTMLReactParserOptions } from "html-react-parser";
import parse, { attributesToProps, domToReact } from "html-react-parser";
import type { ComponentType, HTMLAttributes } from "react";

import { cn } from "~/utils/classnames";

const htmlParserOptions: HTMLReactParserOptions = {
  trim: false,
  replace: (domNode) => {
    if (domNode.type === "script") {
      return <></>;
    }
    if (domNode.type === "style") {
      return <></>;
    }
    if (domNode.type === "comment") {
      return <></>;
    }
    // Replace <a> by Remix <Link> and convert all attributes ro react attributes and put children into the link
    if (domNode.type === "tag" && domNode.name === "a") {
      const { href, className, tabIndex, ...props } = attributesToProps(domNode.attribs);
      return (
        <Link to={href?.toString() || ""} className="font-medium underline" {...props}>
          {domToReact(domNode.children as DOMNode[], htmlParserOptions)}
        </Link>
      );
    }
  },
};

export const HtmlParser = ({
  html,
  className,
  as,
  ...props
}: {
  html: string;
  className?: string;
  as?: keyof JSX.IntrinsicElements | ComponentType<any>;
} & HTMLAttributes<HTMLDivElement>) => {
  const Component = as || "div";
  return (
    <Component
      className={cn(
        className,
        "prose-ul:bulleted-list prose !font-main leading-normal *:!font-main prose-headings:mb-2 prose-headings:font-medium prose-h1:text-3xl prose-h2:text-2xl prose-h3:text-xl prose-h4:text-lg prose-h5:text-base prose-p:mb-4 prose-p:max-w-7xl prose-p:text-base prose-p:leading-normal prose-p:text-grey-700 prose-ol:mb-4 prose-ul:mb-4 prose-li:mt-2"
      )}
      {...props}
    >
      {parse(html, htmlParserOptions)}
    </Component>
  );
};
